<template>
  <div class="serveBindingManage">
    <ds-header title="限时领券"></ds-header>
    <a-form layout="inline">
      <a-form-item label="活动名称：">
        <a-input v-model.trim="searchForm.name" placeholder="请输入活动名称" class="inputClass" />
      </a-form-item>
      <a-form-item label="活动关联券ID：">
        <a-input v-model.trim="searchForm.activityId" placeholder="请输入活动关联券ID" class="inputClass" />
      </a-form-item>
      <a-form-item label="活动项目：">
        <a-select
          mode="tags"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.projectIds"
        >
          <a-select-option v-for="item in projectList" :key="item.id" :value="item.id">
            {{ item.project_name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="状态：">
        <a-select
          style="width: 200px"
          placeholder="请选择状态"
          v-model="searchForm.status"
        >
          <a-select-option v-for="item in status" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="add()"> <a-icon type="plus" /> 新增 </a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="view(record)"> 查看 </a-button>
          <a-button class="btnClass" type="link" @click="amend(record)" v-if="record.status === 0"> 编辑 </a-button>
          <a-button class="btnClass" type="link" @click="restart(record)">
            <span v-if="record.status === 0">启用</span>
            <span v-else>禁用</span>
          </a-button>
          <a-button class="btnClass" type="link" @click="del(record)" v-if="record.status === 0"> 删除 </a-button>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import {projectName} from "@/api/modularManagement";
import { getLimitedTimeCouponList, toDisableEnable, deleteLimitedTimeCouponById } from '@/api/limitedTimeCouponManagement'
export default {
  name: "LimitedTimeCoupon",
  data() {
    return {
      status: [
        {
          label: '全部',
          value: ''
        },
        {
          label: '启用',
          value: 1
        },
        {
          label: '禁用',
          value: 0
        }
      ],
      searchForm: {
        name: undefined,
        activityId: undefined,
        projectIds: undefined,
        status: ''
      },
      // 项目名称
      projectList: [],
      table: {
        dataInfo: [],
        columns: [
          {
            title: "活动名称",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "活动关联券ID",
            dataIndex: "activityId",
            key: "activityId",
          },
          {
            title: "活动项目",
            dataIndex: "projectNames",
            key: 'projectNames',
          },
          {
            title: "状态",
            dataIndex: "status",
            key: "status",
            customRender(text) {
              return text === 1 ? '启用' : '禁用';
            },
          },
          {
            title: "操作人",
            dataIndex: "operateUser",
            key: "operateUser",
          },
          {
            title: "更新时间",
            dataIndex: "gmtModified",
            key: "gmtModified",
            customRender(text) {
              return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-";
            },
          },
          {
            title: "操作",
            key: "action",
            width: "200px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          }
        }
      }
    }
  },
  created() {
    // 获取所有项目
    this.projectNameList()
    // 列表
    this.searchFormList()
  },
  methods: {
    // 搜索
    search() {
      this.table.pagination.current = 1;
      this.searchFormList();
    },

    // 重制
    resetSearchForm() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.searchForm = this.$options.data.call(this).searchForm;
      this.searchFormList();
    },

    // 新增
    add() {
      this.$router.push({
        path: "/limitedTimeCouponManagement/add",
        query: {
          type: "add",
        },
      });
    },

    // 查看
    view(record) {
      var id = record.id;
      this.$router.push({
        path: "/limitedTimeCouponManagement/add",
        query: {
          id: id,
          type: "view",
        },
      });
    },
    // 修改
    amend(record) {
      let id = record.id;
      this.$router.push({
        path: "/limitedTimeCouponManagement/add",
        query: {
          id: id,
          type: "edit",
        },
      });
    },

    // 启用禁用
    restart(record) {
      let that = this;
      let status = ''
      let title = ''
      if (record.status === 0) {
        title = "确定启用该 【 " + record.name + " 】 数据吗？"
        status = 1;
      } else {
        title = "确定禁用该 【 " + record.name + " 】 数据吗？"
        status = 0;
      }
      this.$confirm({
        title: title,
        onOk() {
          let id = record.id;
          toDisableEnable(id, status).then((res) => {
            if (res.code === "200") {
              that.$message.success("操作成功");
              that.table.pagination.current = 1;
              that.searchFormList();
            } else {
              that.$message.error("操作失败");
            }
          });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },

    // 删除
    del(record) {
      let that = this;
      this.$confirm({
        title: "确定要删除该 【 " + record.name + " 】 数据吗？",
        onOk() {
          let id = record.id;
          deleteLimitedTimeCouponById(id).then((res) => {
            if (res.code === "200") {
              that.$message.success("操作成功");
              that.table.pagination.current = 1;
              that.searchFormList();
            } else {
              that.$message.error("操作失败");
            }
          });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },

    // 列表
    async searchFormList() {
      let params = {};
      params.name = this.searchForm.name
      params.projectIds = this.searchForm.projectIds ? this.searchForm.projectIds.toString() : ''
      params.activityId = this.searchForm.activityId
      params.status = this.searchForm.status
      params.currentPage = this.table.pagination.current;
      params.pageSize = this.table.pagination.pageSize
      const res = await getLimitedTimeCouponList(params);
      const { code,data: {items, total} } = res
      if (code === '200') {
        this.table.dataInfo = items;
        this.table.pagination.total = total
      }
    },

    // 项目列表
    async projectNameList() {
      const res = await projectName();
      const {code, data:{items}} = res;
      if (code === '200') {
        this.projectList = items;

      }
    },

    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.searchFormList();
    },
  }
}
</script>

<style lang="scss" scoped>
.btnClass {
  padding: 0px;
  padding-right: 5px;
}
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.inputClass {
  width: 200px;
}
.imgContainer {
  width: 80px;
  height: 80px;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
